import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export default function LoadingSpinner() {
  return (
    <Grid
      container
      justify="center"
      style={{ marginTop: 32, marginBottom: 32 }}
    >
      <CircularProgress disableShrink style={{ alignSelf: "center" }} />
    </Grid>
  );
}
