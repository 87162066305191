import React, { useState, useEffect, Suspense, lazy } from "react";
import StripeScriptLoader from "react-stripe-script-loader";
import { StripeProvider } from "react-stripe-elements";
import _ from "lodash";
import { Router, navigate, Redirect } from "@reach/router";
import { Pages, CheckoutPage } from "Navigation";
import { Elements } from "react-stripe-elements";
import MenuHeader from "components/MenuHeader";
import { Variants as Typo, colors } from "styles/Styleguide";
import { makeStyles } from "@material-ui/core";
//import MenuFooter from "components/MenuFooter";
import { planList } from "Constants";
import { getStoredUser } from "services/auth.js";
import LoadingSpinner from "components/LoadingSpinner";
import LoadingPage from "pages/LoadingPage";
import { BasicPage } from "components/PageLayouts";

// Imported pages
const AccountInfo = lazy(() => import("./Checkout/AccountInfo.js"));
const ShippingInfo = lazy(() => import("./Checkout/ShippingInfo.js"));
const Payment = lazy(() => import("./Checkout/Payment.js"));
const CheckoutPlanSelector = lazy(() =>
  import("components/CheckoutPlanSelector")
);
const CheckoutDone = lazy(() => import("components/CheckoutDone"));

export default function Checkout(props) {
  const styles = useStyles();

  const propsState = props.location.state;

  //const [activePlan, setActivePlan] = useState(planList[0]);
  const [activePlan, setActivePlan] = useState(
    _.get(propsState, "activePlan", {})
  );

  // Filled in ShipmentInfo window
  const [userInfo, setUserInfo] = useState({});
  const [gifteeInfo, setGifteeInfo] = useState({});
  const [shippingDetails, setShippingDetails] = useState({});
  const [giftMessage, setGiftMessage] = useState({ name: "", message: "" });
  const [isGift, setIsGift] = useState(false);
  const [promoInfo, setPromoInfo] = useState({});

  // Filled in Payment window
  const [billingDetails, setBillingDetails] = useState({});
  const [cardEnding, setCardEnding] = useState("");

  const user = getStoredUser();
  const redirectDashboard = !!user && !!user.subscriptionId;
  const redirectPreorderDashboard =
    !!user && !!user.subscription && user.subscription !== "none";

  //setShippingAddress(address);
  //const [checkoutState, setCheckoutState] = useState(CheckoutState.Plan);

  const RenderPayment = () => {
    return (
      <Elements>
        <Payment
          plan={activePlan}
          shippingInfo={shippingDetails}
          userInfo={userInfo}
          gifteeInfo={gifteeInfo}
          isGift={isGift}
          giftMessage={giftMessage}
          onSuccess={billingAddress => {
            window.gtag("event", "subscribe_succeeded", {
              plan: activePlan.title
            });
            setBillingDetails(billingDetails);
            navigate(Pages.Checkout + CheckoutPage.Done);
          }}
          promoInfo={promoInfo}
          setPromoInfo={setPromoInfo}
        />
      </Elements>
    );
  };

  return redirectDashboard ? (
    <Redirect to={Pages.Dashboard} noThrow />
  ) : redirectPreorderDashboard ? (
    <Redirect to={Pages.Preorder} noThrow />
  ) : (
    <StripeScriptLoader
      uniqueId="stripeJS"
      script="https://js.stripe.com/v3/"
      loader={<LoadingSpinner />}
    >
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <div>
          <MenuHeader />

          <Suspense fallback={<LoadingPage />}>
            <Router>
              <BasicPage
                component={CheckoutPlanSelector}
                path={CheckoutPage.Plan}
                title={"Become a member"}
                description={
                  "Join now for a full skincare regimen that's tailored to your skin and environment."
                }
                default
                plan={activePlan}
                onClick={plan => {
                  window.gtag("event", "checkout_plan_selected", {
                    plan: plan.title
                  });
                  setActivePlan(plan);

                  //setEmail(user.email);
                  //setObjectId(user.objectId);
                  navigate(Pages.Checkout + CheckoutPage.ShippingInfo);
                }}
              />

              <BasicPage
                component={ShippingInfo}
                path={CheckoutPage.ShippingInfo}
                title={"Shipping Info"}
                plan={activePlan}
                //email={email}
                onSubmit={(
                  _isGift,
                  _userInfo,
                  _gifteeInfo,
                  shippingAddress,
                  gifterMessage
                ) => {
                  window.gtag("event", "checkout_shipment_address", {
                    plan: activePlan.title
                  });

                  const user = _isGift ? _gifteeInfo : _userInfo;
                  const shippingInfo = {
                    ...shippingAddress,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phone: user.phone
                  };
                  setIsGift(_isGift);
                  setShippingDetails(shippingInfo);
                  setUserInfo(_userInfo);
                  setGifteeInfo(_gifteeInfo);
                  setGiftMessage(gifterMessage);

                  navigate(Pages.Checkout + CheckoutPage.Payment);
                }}
                promoInfo={promoInfo}
                setPromoInfo={setPromoInfo}
              />

              <BasicPage
                component={RenderPayment}
                path={CheckoutPage.Payment}
                title={"Payment Details"}
              />

              <BasicPage
                component={CheckoutDone}
                path={CheckoutPage.Done}
                title={"Order Confirmed"}
                plan={activePlan}
                details={shippingDetails}
                userDetails={userInfo}
                cardDigits={cardEnding}
                isGift={isGift}
                promoInfo={promoInfo}
              />
            </Router>
          </Suspense>
        </div>
      </StripeProvider>
    </StripeScriptLoader>
  );
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 160,
    //marginBottom: 80,
    [theme.breakpoints.down("sm")]: {
      marginTop: 32
    }
  },
  titleLight: {
    color: theme.palette.primary.light
  },
  title: {
    color: colors.primary
  }
}));
