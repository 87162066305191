// Product logos and images
import imgPerricone from "img/products/imgPerricone.jpg";
import imgPeterThomasRoth from "img/products/imgPeterThomasRoth.jpg";
import imgAquareveal from "img/products/imgAquareveal.jpg";
import imgAvant from "img/products/imgAvant.jpg";
import imgChuda from "img/products/imgChuda.jpg";
import imgClarityRx from "img/products/imgClarityRx.jpg";
import imgCodex from "img/products/imgCodex.jpg";
import imgGoldfaden from "img/products/imgGoldfaden.jpg";
import imgKaruna from "img/products/imgKaruna.jpg";
import imgSaltAndStone from "img/products/imgSaltAndStone.jpg";
import imgSkinDesignLondon from "img/products/imgSkinDesignLondon.jpg";
import imgTheBetterSkinCo from "img/products/imgTheBetterSkinCo.jpg";
import imgThisWorks from "img/products/imgThisWorks.jpg";
import imgBabor from "img/products/imgBabor.jpg";
import imgGoodScienceBeauty from "img/products/imgGoodScienceBeauty.jpg";
import imgInstytutum from "img/products/imgInstytutum.jpg";
import imgOgee from "img/products/imgOgee.jpg";
import imgAhava from "img/products/imgAhava.jpg";
import imgAvya from "img/products/imgAvya.jpg";
import imgEmmaHardie from "img/products/imgEmmaHardie.jpg";
import imgErasa from "img/products/imgErasa.jpg";
import imgNoBS from "img/products/imgNoBS.jpg";
import imgNonGenderSpecific from "img/products/imgNonGenderSpecific.jpg";
import imgPatchology from "img/products/imgPatchology.jpg";
import imgTheOrganicPharmacy from "img/products/imgTheOrganicPharmacy.jpg";
import imgBeautyStat from "img/products/imgBeautyStat.jpg";
import imgOSEA from "img/products/imgOSEA.jpg";
import imgSkinAuthority from "img/products/imgSkinAuthority.jpg";
import imgSnowFox from "img/products/imgSnowFox.jpg";
import imgStriVectin from "img/products/imgStriVectin.jpg";
import imgSupermood from "img/products/imgSupermood.jpg";
import imgAromatherapy from "img/products/imgAromatherapy.jpg";
import imgKhusKhus from "img/products/imgKhusKhus.jpg";
import imgOrganicPharmacy from "img/products/imgOrganicPharmacy.jpg";
import imgPhilosophy from "img/products/imgPhilosophy.jpg";
import imgBioderma from "img/products/imgBioderma.jpg";
import imgEmbryolisse from "img/products/imgEmbryolisse.jpg";
import imgPurlisse from "img/products/imgPurlisse.jpg";
import imgVeganMia from "img/products/imgVeganMia.jpg";
import imgLuxeBotanics from "img/products/imgLuxeBotanics.jpg";
import imgIndeedLabs from "img/products/imgIndeedLabs.jpg";
import imgBynacht from "img/products/imgBynacht.jpg";
import imgKubraKay from "img/products/imgKubraKay.jpg";
import imgSkynIceland from "img/products/imgSkynIceland.jpg";
import imgMzSkin from "img/products/imgMzSkin.jpg";
import imgLeCleanse from "img/products/imgLeCleanse.jpg";
import imgSixGldn from "img/products/imgSixGldn.jpg";

// logos
import logoPerricone from "img/products/logoPerricone.svg";
import logoPeterThomasRoth from "img/products/logoPeterThomasRoth.svg";
import logoAquareveal from "img/products/logoAquareveal.svg";
import logoAvant from "img/products/logoAvant.svg";
import logoChuda from "img/products/logoChuda.svg";
import logoClarityRx from "img/products/logoClarityRx.svg";
import logoCodex from "img/products/logoCodex.svg";
import logoGoldfaden from "img/products/logoGoldfaden.png";
import logoKaruna from "img/products/logoKaruna.png";
import logoSaltAndStone from "img/products/logoSaltAndStone.svg";
import logoSkinDesignLondon from "img/products/logoSkinDesignLondon.svg";
import logoTheBetterSkinCo from "img/products/logoTheBetterSkinCo.svg";
import logoThisWorks from "img/products/logoThisWorks.svg";
import logoBabor from "img/products/logoBabor.svg";
import logoGoodScienceBeauty from "img/products/logoGoodScienceBeauty.svg";
import logoInstytutum from "img/products/logoInstytutum.svg";
import logoOgee from "img/products/logoOgee.svg";
import logoAhava from "img/products/logoAhava.svg";
import logoAvya from "img/products/logoAvya.svg";
import logoEmmaHardie from "img/products/logoEmmaHardie.svg";
import logoErasa from "img/products/logoErasa.svg";
import logoNoBS from "img/products/logoNoBS.svg";
import logoNonGenderSpecific from "img/products/logoNonGenderSpecific.svg";
import logoPatchology from "img/products/logoPatchology.svg";
import logoTheOrganicPharmacy from "img/products/logoTheOrganicPharmacy.svg";
import logoBeautyStat from "img/products/logoBeautyStat.svg";
import logoOSEA from "img/products/logoOSEA.svg";
import logoSkinAuthority from "img/products/logoSkinAuthority.svg";
import logoSnowFox from "img/products/logoSnowFox.svg";
import logoStriVectin from "img/products/logoStriVectin.svg";
import logoSupermood from "img/products/logoSupermood.svg";
import logoAromatherapy from "img/products/logoAromatherapy.svg";
import logoKhusKhus from "img/products/logoKhusKhus.svg";
import logoOrganicPharmacy from "img/products/logoOrganicPharmacy.svg";
import logoPhilosophy from "img/products/logoPhilosophy.svg";
import logoBioderma from "img/products/logoBioderma.svg";
import logoEmbryolisse from "img/products/logoEmbryolisse.svg";
import logoPurlisse from "img/products/logoPurlisse.svg";
import logoVeganMia from "img/products/logoVeganMia.svg";
import logoLuxeBotanics from "img/products/logoLuxeBotanics.svg";
import logoIndeedLabs from "img/products/logoIndeedLabs.svg";
import logoBynacht from "img/products/logoBynacht.svg";
import logoKubraKay from "img/products/logoKubraKay.svg";
import logoSkynIceland from "img/products/logoSkynIceland.svg";
import logoMzSkin from "img/products/logoMzSkin.svg";
import logoLeCleanse from "img/products/logoLeCleanse.svg";
import logoSixGldn from "img/products/logoSixGldn.svg";

export const ProductsList = [
  { icon: imgPerricone, logo: logoPerricone },
  { icon: imgPeterThomasRoth, logo: logoPeterThomasRoth },
  { icon: imgThisWorks, logo: logoThisWorks },
  { icon: imgAquareveal, logo: logoAquareveal },
  { icon: imgAvant, logo: logoAvant },
  { icon: imgChuda, logo: logoChuda },
  { icon: imgClarityRx, logo: logoClarityRx },
  { icon: imgCodex, logo: logoCodex },
  { icon: imgGoldfaden, logo: logoGoldfaden },
  { icon: imgKaruna, logo: logoKaruna },
  { icon: imgSaltAndStone, logo: logoSaltAndStone },
  { icon: imgSkinDesignLondon, logo: logoSkinDesignLondon },
  { icon: imgTheBetterSkinCo, logo: logoTheBetterSkinCo },
  { icon: imgBabor, logo: logoBabor },
  { icon: imgGoodScienceBeauty, logo: logoGoodScienceBeauty },
  { icon: imgInstytutum, logo: logoInstytutum },
  { icon: imgOgee, logo: logoOgee },
  { icon: imgAhava, logo: logoAhava },
  { icon: imgAvya, logo: logoAvya },
  { icon: imgEmmaHardie, logo: logoEmmaHardie },
  { icon: imgErasa, logo: logoErasa },
  { icon: imgNoBS, logo: logoNoBS },
  { icon: imgNonGenderSpecific, logo: logoNonGenderSpecific },
  { icon: imgPatchology, logo: logoPatchology },
  { icon: imgTheOrganicPharmacy, logo: logoTheOrganicPharmacy },
  { icon: imgBeautyStat, logo: logoBeautyStat },
  { icon: imgOSEA, logo: logoOSEA },
  { icon: imgSkinAuthority, logo: logoSkinAuthority },
  { icon: imgSnowFox, logo: logoSnowFox },
  { icon: imgStriVectin, logo: logoStriVectin },
  { icon: imgSupermood, logo: logoSupermood },
  { icon: imgAromatherapy, logo: logoAromatherapy },
  { icon: imgKhusKhus, logo: logoKhusKhus },
  { icon: imgOrganicPharmacy, logo: logoOrganicPharmacy },
  { icon: imgPhilosophy, logo: logoPhilosophy },
  { icon: imgBioderma, logo: logoBioderma },
  { icon: imgEmbryolisse, logo: logoEmbryolisse },
  { icon: imgPurlisse, logo: logoPurlisse },
  { icon: imgVeganMia, logo: logoVeganMia },
  { icon: imgLuxeBotanics, logo: logoLuxeBotanics },
  { icon: imgIndeedLabs, logo: logoIndeedLabs },
  { icon: imgBynacht, logo: logoBynacht },
  { icon: imgKubraKay, logo: logoKubraKay },
  { icon: imgSkynIceland, logo: logoSkynIceland },
  { icon: imgMzSkin, logo: logoMzSkin },
  { icon: imgLeCleanse, logo: logoLeCleanse },
  { icon: imgSixGldn, logo: logoSixGldn }
];
