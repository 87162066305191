import React from "react";
import { Typography } from "@material-ui/core";
import { Variants, ThemeVariants, TextStyles } from "styles/Styleguide";

type Props = {
  type: Variants,
  styling: any // Material UI style class should be here
};
export default function Label(props: Props) {
  const styles = TextStyles();

  return (
    <Typography
      variant={ThemeVariants[props.type]}
      className={`${styles[props.type]} ${props.styling}`}
      {...props}
    >
      {props.children}
    </Typography>
  );
}
