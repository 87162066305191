import React, { Suspense, lazy } from "react";
import { Router, Redirect } from "@reach/router";
import { ScrollToTop } from "utils/ScrollToTop";
import LoadingPage from "pages/LoadingPage";
import { BasicPage, PrivatePage } from "components/PageLayouts";

import Checkout from "./pages/Checkout";
// Prelaunch phase
const TempHome = lazy(() => import("./pages/HomePrelaunch"));
const Promo = lazy(() => import("./pages/Promo"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const DashboardPreorder = lazy(() => import("pages/DashboardPreorder"));
// All launch pages
const NotFound = lazy(() => import("./pages/NotFound"));
const Home = lazy(() => import("./pages/Home"));
const Billing = lazy(() => import("./pages/Billing"));
const Login = lazy(() => import("./pages/Login"));
const Partners = lazy(() => import("pages/Partners"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const Terms = lazy(() => import("pages/Terms"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Settings = lazy(() => import("./pages/Settings"));
const Faq = lazy(() => import("pages/Faq"));
const EmailConfirmed = lazy(() => import("pages/EmailConfirmed"));
const EmailConfirmFailed = lazy(() => import("pages/EmailConfirmFailed"));
const EmailSetup = lazy(() => import("pages/EmailSetup"));
const PasswordReset = lazy(() => import("pages/PasswordReset"));

export const Pages = {
  // Main pages
  Home: "/",
  Login: "/sign-in",
  Dashboard: "/dashboard",
  Preorder: "/preorder",
  Partners: "/partners",
  Privacy: "/privacy",
  Contacts: "/contact", // TODO: Rename source file and the enum to Contact
  Terms: "/terms",
  Promo: "/promo",
  Checkout: "/checkout",
  Settings: "/settings",
  Faq: "/faq",
  // Helper pages
  EmailConfirmed: "/email-confirmed",
  EmailConfirmFailed: "/email-confirm-failed",
  EmailSetup: "/account-setup",
  PasswordReset: "/password-reset"
};

export const FAQ = {
  Facegenius: "facegenius",
  SkinScanner: "skinscanner",
  App: "app",
  Shipping: "shipping",
  Account: "account",
  Warnings: "warnings"
};

export const HomeTags = {
  Brands: "brands"
};

export const CheckoutPage = {
  Plan: "/",
  Account: "/account",
  ShippingInfo: "/shipping-info",
  Payment: "/payment",
  Done: "/done"

  //Preorder: "/preorder"
};

export default function Navigation() {
  return process.env.REACT_APP_LAUNCH_NAME === "PRELAUNCH" ? (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        <TempHome path={Pages.Home} />
        <Contacts path={Pages.Contacts} />
        <Promo path={Pages.Promo} />

        <Redirect from="contact.html" to={Pages.Contacts} noThrow />
        <Redirect from="promo.html" to={Pages.Promo} noThrow />
        <NotFound default />
      </Router>
    </Suspense>
  ) : (
    <Suspense fallback={<LoadingPage />}>
      <Router primary={false}>
        <ScrollToTop path="/">
          <BasicPage
            component={Home}
            path={Pages.Home}
            description={
              "Get your complete personalized skincare regimen, and the SkinScanner™ included for free with your subscription. Join FaceGenius today."
            }
          />
          <BasicPage
            component={Login}
            path={Pages.Login}
            title={"Sign in"}
            description={"Sign in or become a FaceGenius member."}
          />
          <BasicPage
            component={Contacts}
            path={Pages.Contacts}
            title={"Contact"}
            description={"Tell us anything. We’re good listeners."}
          />
          <BasicPage
            component={Faq}
            path={Pages.Faq}
            title={"FAQ"}
            description={"Looking for answers? Here’s our FAQ."}
          />

          {/* Need to load this directly since it has another router inside */}
          <Checkout path={Pages.Checkout + "/*"} />

          <BasicPage
            component={EmailConfirmed}
            path={Pages.EmailConfirmed}
            title={"Email Confirmation"}
          />
          <BasicPage
            component={EmailConfirmFailed}
            path={Pages.EmailConfirmFailed}
            title={"Email Confirmation"}
          />
          <BasicPage
            component={EmailSetup}
            path={Pages.EmailSetup}
            title={"Activate Your Account"}
          />
          <BasicPage
            component={PasswordReset}
            path={Pages.PasswordReset}
            title={"Password Reset"}
          />
          <BasicPage component={NotFound} default title={"Page Not Found"} />

          <PrivatePage
            path={Pages.Dashboard}
            component={Dashboard}
            title={"Dashboard"}
          />
          <PrivatePage
            path={Pages.Preorder}
            component={DashboardPreorder}
            title={"Preparing Order"}
          />
          <PrivatePage
            path={Pages.Settings}
            component={Settings}
            title={"Settings"}
          />

          <Redirect from="contact.html" to={Pages.Contacts} noThrow />
          <Redirect from="promo.html" to={Pages.Promo} noThrow />
          <Redirect from="/login" to={Pages.Login} noThrow />

          {/*<Partners path={Pages.Partners} />*/}
          {/*<PrivacyPolicy path={Pages.Privacy} />*/}
          {/*<Terms path={Pages.Terms} />*/}
        </ScrollToTop>
      </Router>
    </Suspense>
  );
}
