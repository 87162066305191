import React, { useState, useLayoutEffect } from "react";
import { navigate } from "@reach/router";
import { isLoginValid, logout } from "services/auth";
import { Pages, HomeTags } from "Navigation";
import { scrollToAnchor } from "utils";
import {
	Menu,
	MenuItem,
	makeStyles,
	Link,
	IconButton,
	Divider,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { IsScreenSizeSmall } from "styles/utils";
import Label from "components/Label";
import { Variants as Typo, colors } from "styles/Styleguide";
import Image from "components/Image";

import fgLogo from "img/FGLOGO.svg";
import fgLogoWhite from "img/FGLOGO_white.svg";
import iconMenu from "img/iconMenu.svg";

export default function MenuHeader({ white }) {
	const dividerColor = white ? colors.white : colors.primary;
	const styles = useStyles({ dividerColor });

	const isSmallScreen = IsScreenSizeSmall(useTheme());

	const [isLoggedIn, setLoggedIn] = useState(false);
	const [menuAnchor, setMenuAnchor] = React.useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const linkButtonStyle = white ? styles.linkButtonWhite : {};

	const BrandsUrl = Pages.Home + "#" + HomeTags.Brands;

	const LoginState = {
		All: "all",
		LoggedIn: "logged-in",
		LoggedOut: "logged-out",
	};

	const NavBarButtons = {
		Brands: "Brands",
		FAQ: "FAQ",
		Contacts: "Contact us",
		Settings: "Settings",
		Dashboard: "Dashboard",
		Logout: "Sign out",
		Checkout: "Become a member",
		Login: "Sign in",
	};

	const NavBarItems = [
		{
			name: NavBarButtons.Brands,
			url: BrandsUrl,
			loginState: LoginState.All,
		},
		{ name: NavBarButtons.FAQ, url: Pages.Faq, loginState: LoginState.All },
		{
			name: NavBarButtons.Contacts,
			url: Pages.Contacts,
			loginState: LoginState.All,
			divider: true,
		},
		{
			name: NavBarButtons.Settings,
			url: Pages.Settings,
			loginState: LoginState.LoggedIn,
		},
		{
			name: NavBarButtons.Dashboard,
			url: Pages.Dashboard,
			loginState: LoginState.LoggedIn,
		},
		{
			name: NavBarButtons.Logout,
			url: "",
			loginState: LoginState.LoggedIn,
		},
		// {
		//   name: NavBarButtons.Checkout,
		//   url: Pages.Checkout,
		//   loginState: LoginState.LoggedOut
		// },
		{
			name: NavBarButtons.Login,
			url: Pages.Login,
			loginState: LoginState.LoggedOut,
		},
	];

	// TODO: We need to show a loading spinner here for it until the response
	useLayoutEffect(() => {
		const checkLogin = async () => {
			const { valid, error } = await isLoginValid();

			if (error) {
				setLoggedIn(false);
			} else {
				setLoggedIn(valid);
			}

			setIsLoading(false);
		};

		checkLogin();
	}, []);

	const onMenuClose = () => {
		setMenuAnchor(null);
	};

	const navigateTo = async (page: string) => {
		onMenuClose();
		await navigate(page);
		scrollToAnchor();
	};

	const onMenuLogout = () => {
		onMenuClose();
		onLogoutClicked();
	};

	const renderFullButtons = () => {
		const envTitle = getEnvTitle();

		return (
			<div className={styles.buttonsContainer}>
				{!_.isEmpty(envTitle) && (
					<Label type={Typo.body} styling={linkButtonStyle}>
						{envTitle}
					</Label>
				)}

				{_.map(NavBarItems, (item) => {
					let canRender = false;
					switch (item.loginState) {
						case LoginState.All:
							canRender = true;
							break;
						case LoginState.LoggedIn:
							canRender = isLoggedIn;
							break;
						case LoginState.LoggedOut:
							canRender = !isLoggedIn;
							break;
						default:
							break;
					}

					return canRender ? (
						<React.Fragment key={item.name}>
							<Link
								component="button"
								className={styles.linkItem}
								onClick={async () => {
									window.gtag("event", "navbar_click", {
										value: item.name,
									});

									if (item.name === NavBarButtons.Logout) {
										onLogoutClicked();
									} else {
										navigateTo(item.url);
									}
								}}
							>
								<Label
									type={Typo.body}
									styling={linkButtonStyle}
								>
									{item.name}
								</Label>
							</Link>
							{!!item.divider && (
								<Divider
									orientation="vertical"
									className={styles.divider}
								/>
							)}
						</React.Fragment>
					) : (
						""
					);
				})}
			</div>
		);
	};

	const renderMenuButton = () => {
		const envTitle = getEnvTitle();

		return (
			<div className={styles.buttonsContainer}>
				<IconButton
					aria-controls="headerMenu"
					aria-haspopup="true"
					onClick={(event) => setMenuAnchor(event.currentTarget)}
				>
					<Image
						src={iconMenu}
						className={styles.icon}
						alt="iconMenu"
					/>
				</IconButton>
				<Menu
					id="headerMenu"
					anchorEl={menuAnchor}
					keepMounted
					open={Boolean(menuAnchor)}
					onClose={onMenuClose}
				>
					{!_.isEmpty(envTitle) && (
						<MenuItem disabled={true}>{envTitle}</MenuItem>
					)}

					{_.map(NavBarItems, (item) => {
						let canRender = false;
						switch (item.loginState) {
							case LoginState.All:
								canRender = true;
								break;
							case LoginState.LoggedIn:
								canRender = isLoggedIn;
								break;
							case LoginState.LoggedOut:
								canRender = !isLoggedIn;
								break;
							default:
								break;
						}

						return canRender ? (
							<MenuItem
								key={item.name}
								onClick={() => {
									window.gtag("event", "navbar_click", {
										value: item.name,
									});

									if (item.name === NavBarButtons.Checkout) {
										window.gtag("event", "open_checkout", {
											location: "NavBar",
										});
									}

									if (item.name === NavBarButtons.Logout) {
										onMenuLogout();
									} else {
										navigateTo(item.url);
									}
								}}
							>
								<Label type={Typo.body}>{item.name}</Label>
							</MenuItem>
						) : (
							""
						);
					})}
				</Menu>
			</div>
		);
	};

	return isLoading ? (
		""
	) : (
		<div className={styles.mainContainer}>
			<Link component="button" onClick={() => navigate(Pages.Home)}>
				<Image
					src={!!white ? fgLogoWhite : fgLogo}
					className={styles.logo}
					alt="fgLogo"
				/>
			</Link>
			{isSmallScreen ? renderMenuButton() : renderFullButtons()}
		</div>
	);
}

const onLogoutClicked = async () => {
	const { success, error } = await logout();
	if (error) {
		console.error("failed to logout", error);
	} else if (success) {
		window.gtag("event", "logout", { user_id: "" });
		window.gtag("set", { user_id: "" });
		if (window.location.pathname === Pages.Home) {
			window.location.reload();
		} else {
			navigate(Pages.Home);
		}
	}
};

const getEnvTitle = () => {
	const isProduction = process.env.REACT_APP_ENV === "Production";
	const envTitle = `(Env: ${process.env.REACT_APP_ENV})`;

	return isProduction ? "" : envTitle;
};

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	buttonsContainer: {
		display: "flex",
		alignItems: "center",
		marginRight: 24,
	},
	item: {
		flex: 1,
		marginLeft: 12,
		marginRight: 12,
		//  flexGrow: "1"
	},
	logo: {
		alignSelf: "flex-start",
		height: 48,
		marginTop: 16,
		marginBottom: 16,
		marginLeft: 24,
	},
	icon: {
		width: 36,
		height: 36,
	},
	linkItem: {
		marginLeft: 24,
	},
	linkButtonWhite: {
		color: colors.white,
	},
	divider: (props) => ({
		marginLeft: 24,
		height: 48,
		opacity: 0.25,
		backgroundColor: props.dividerColor,
	}),
}));
