// Image component to be used with https://github.com/Brigad/ideal-image-loader

/* eslint-disable */
import PropTypes from "prop-types";
import React from "react";
import { Skeleton } from "@material-ui/lab";

// http://probablyprogramming.com/2009/03/15/the-tiniest-gif-ever
const EMPTY_GIF = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";

/* eslint-enable */

// Intended to work with @brigad/ideal-image-loader returned format
export const getImageSrc = src => {
  const x1 = src && src.x1 ? src.x1 : src;
  const x2 = src && src.x2 ? src.x2 : null;
  const x3 = src && src.x3 ? src.x3 : null;

  const base64 = x1 && x1.preSrc ? x1.preSrc : EMPTY_GIF;

  const src1 = x1 && x1.src ? x1.src : x1;
  const src2 = x2 && x2.src ? x2.src : "";
  const src3 = x3 && x3.src ? x3.src : "";
  const webp1 = x1 && x1.webp ? x1.webp : "";
  const webp2 = x2 && x2.webp ? x2.webp : "";
  const webp3 = x3 && x3.webp ? x3.webp : "";

  const src1FormattedFixed = src1;
  const src2FormattedFixed = src2 ? `${src2} 2x` : "";
  const src3FormattedFixed = src3 ? `${src3} 3x` : "";
  const webp1FormattedFixed = webp1;
  const webp2FormattedFixed = webp2 ? `${webp2} 2x` : "";
  const webp3FormattedFixed = webp3 ? `${webp3} 3x` : "";

  const srcSet = [src1FormattedFixed, src2FormattedFixed, src3FormattedFixed]
    .filter(Boolean)
    .join(", ");

  const srcSetWebp = [
    webp1FormattedFixed,
    webp2FormattedFixed,
    webp3FormattedFixed
  ]
    .filter(Boolean)
    .join(", ");

  return { srcSet: srcSet, srcSetWebp: srcSetWebp, base64: base64, src: src1 };
};

const Image = ({ src, alt, className, style, useDataSrc }) => {
  const { srcSet, srcSetWebp, base64 } = getImageSrc(src);

  return (
    <picture>
      <source
        type="image/webp"
        {...(useDataSrc
          ? { "data-srcset": srcSetWebp }
          : { srcSet: srcSetWebp })}
      />
      <source
        {...(useDataSrc ? { "data-srcset": srcSet } : { srcSet: srcSet })}
      />
      <img src={base64} alt={alt} className={className} style={style} />
    </picture>
  );
};

export default Image;
