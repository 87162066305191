import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { SeasonDates } from "Constants";

// For more convenient selection of ThemeVariants and TextStyles, aka. enum
export const Variants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle: "subtitle",
  body: "body",
  link: "link",
  footnoteHead: "footnoteHead",
  footnote: "footnote"
};

// This maps our own set of variants to a rigid set of Material UI variants
export const ThemeVariants = {
  // Other possible variants: h6, subtitle2, button, caption, overline
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle: "subtitle1",
  body: "body1",
  link: "body2",
  footnoteHead: "subtitle2",
  footnote: "body2"
};

export const colors = {
  black: "#000",
  white: "#fff",
  primaryBlack: "#474c5c",
  primaryBlack_op_4: "rgba(71, 76, 92, 0.4)",
  primaryBlack_op_7: "rgba(71, 76, 92, 0.7)",
  primaryAccent: "#ef5f03",
  primary: "#51608F", // TODO: rename to primaryText
  primaryLight: "#99A2BF",
  blueLight: "#54618c",
  green: "#1BD9A7",
  orange: "#EF5F03",
  yellowLight: "#F8F2DE",
  gray: "rgba(71,76,92,0.4)",
  lightGray: "rgba(71,76,92,0.2)",
  red: "#EA3B6B"
};

export const getSeasonColor = () => {
  const dateNow = moment();

  if (dateNow.isAfter(moment(SeasonDates.Fall))) {
    return "linear-gradient(129.68deg, #FFAEC1 20%, #FFA83F 85%)";
  } else if (dateNow.isAfter(moment(SeasonDates.Summer))) {
    return "linear-gradient(129.68deg, #40EEFB 20%, #FCCC30 85%)";
  } else if (dateNow.isAfter(moment(SeasonDates.Spring))) {
    return "linear-gradient(129.68deg, #FAEB47 20%, #F522BE 85%)";
  } else {
    return "linear-gradient(129.68deg, #6BF5FF 20%, #DC44F3 85%)";
  }
};

export const TextStyles = makeStyles(theme => ({
  h1: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "48px",
    lineHeight: "54px",
    letterSpacing: "-0.75px",
    color: colors.primary,

    // Override for mobile
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Helvetica Neue",
      fontWeight: "bold",
      fontSize: "34px",
      lineHeight: "40px",
      letterSpacing: "-0.5px",
      color: colors.primary
    }
  },
  h2: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "38px",
    lineHeight: "44px",
    letterSpacing: "-0.5px",
    color: colors.primary,

    // Override for mobile
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Helvetica Neue",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "34px",
      letterSpacing: "-0.25px",
      color: colors.primary
    }
  },
  h3: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "28px",
    letterSpacing: "-0.25px",
    color: colors.primary,

    // Override for mobile
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Helvetica Neue",
      fontWeight: "bold",
      fontSize: "22px",
      lineHeight: "28px",
      letterSpacing: "-0.1px",
      color: colors.primary
    }
  },
  h4: {
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "-0.1px",
    color: colors.primary
  },
  h5: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    color: colors.primary
  },
  h6: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.25px",
    color: colors.primary
  },
  subtitle: {
    fontFamily: "Helvetica Neue",
    fontSize: "18px",
    lineHeight: "24px",
    color: colors.primary
  },
  body: {
    fontFamily: "Helvetica Neue",
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.primary
  },
  link: {
    fontFamily: "Helvetica Neue",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: colors.orange
  },
  footnote: {
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    lineHeight: "18px",
    color: colors.primary
  },
  footnoteHead: {
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
    color: colors.primary
  }
}));
