import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Backendless from "backendless";
import { hotjar } from "react-hotjar";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { version } from "../package.json";
import Helmet from "react-helmet";
import _ from "lodash";
import { getStoredUser } from "services/auth.js";

// TODO: change require to import
require("dotenv").config();

// Determine if this is `npm start` or a proper production build (regardless
// of `REACT_APP_ENV` environment)
const isProductionBuild = process.env.NODE_ENV === "production";

const userData = getStoredUser();
const userId = _.get(userData, "objectId", "");
const gaProps = {
  debug_mode: !isProductionBuild,
  ...(!!userId && { user_id: userId })
};

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_ID,
  appVersion: version,
  enabledReleaseStages: ["Production", "Staging"],
  releaseStage: process.env.REACT_APP_ENV,
  plugins: [new BugsnagPluginReact()]
});

Backendless.initApp(
  process.env.REACT_APP_BACKENDLESS_APP_ID,
  process.env.REACT_APP_BACKENDLESS_API_KEY
);

hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);

const GoogleStructuredData = {
  "@context": "http://schema.org/",
  name: "FaceGenius",
  "@type": "Organization",
  description:
    "FaceGenius is your connected skin advisor that gives you the best products for your skin, when you need them",
  url: "https://facegenius.com",
  logo: process.env.PUBLIC_URL + "/FaceGeniusLogotype-200px.png",
  sameAs: [process.env.REACT_APP_INSTARGRAM_URL]
};

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(
  <ErrorBoundary>
    <Helmet>
      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_KEY}`}
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${process.env.REACT_APP_GA_KEY}", ${JSON.stringify(
          gaProps
        )});
        `}
      </script>
      {/* Google Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(GoogleStructuredData)}
      </script>
    </Helmet>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
