import React from "react";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default function ErrorHandler(props) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // TODO: implement logout
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(error, info) => {
        console.error("Got ErrorBoundary error:", error, info);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
