// Press & Awards logos
import logoAllure from "img/awards/logoAllure.svg";
import logoCnn from "img/awards/logoCnn.svg";
import logoInnovation from "img/awards/logoInnovation.svg";
import logoJohnson from "img/awards/logoJohnson.svg";
import logoKellyRyan from "img/awards/logoKellyRyan.svg";
import logoRefinery29 from "img/awards/logoRefinery29.svg";
import logoUsaToday from "img/awards/logoUsaToday.svg";
import logoYahoo from "img/awards/logoYahoo.svg";
import logoElle from "img/awards/logoElle.svg";
import logoBusinessInsider from "img/awards/logoBusinessInsider.svg";
import logoCnet from "img/awards/logoCnet.svg";
import logoDesignboom from "img/awards/logoDesignboom.svg";
import logoDigitalTrends from "img/awards/logoDigitalTrends.svg";
import logoPopsugar from "img/awards/logoPopsugar.svg";
import logoWwd from "img/awards/logoWwd.svg";

export type AwardItem = {
  image: any,
  title: string,
  link: string
};

export const AwardsList = [
  {
    name: "Allure",
    image: logoAllure,
    title: "“Must buy! Skin scanner is like a pocket-sized dermatologist.”",
    link: "https://www.allure.com/story/neutrogena-skin360-skinscanner-fitskin"
  },
  {
    name: "Elle",
    image: logoElle,
    title: "“The high tech gadgets you'll actually want to put near your face”",
    link:
      "https://www.elle.com/beauty/makeup-skin-care/a21272098/high-tech-facials-face-gym-skinceuticals-estee-lauder-july-2018/"
  },
  {
    name: "Digital Trends",
    image: logoDigitalTrends,
    title: "“The most exciting beauty accessory we saw this year”",
    link:
      "https://www.digitaltrends.com/mobile/neutrogena-and-fitskin-release-skinscanner-for-skincare-routines/"
  },
  {
    name: "Popsugar",
    image: logoPopsugar,
    title: "“Buzzy beauty gadget that is so worth the hype”",
    link:
      "https://www.popsugar.com/beauty/photo-gallery/44582478/image/44582492/Neutrogena-Skin360-SkinScanner-Launching-Summer-2018"
  },
  {
    name: "Yahoo",
    image: logoYahoo,
    title: "“Best New Beauty Technology”",
    link:
      "https://sports.yahoo.com/best-mobile-accessories-ces-2018-183856906.html"
  },
  {
    name: "Cnet",
    image: logoCnet,
    title: "“Best UI I've Seen“",
    link:
      "https://www.cnet.com/videos/neutrogenas-iphone-attachment-takes-a-deep-look-at-your-skin/"
  },
  {
    name: "USA Today",
    image: logoUsaToday,
    title: "Editor's Choice Award",
    link:
      "https://www.usatoday.com/story/news/pr/2018/01/11/reviewed-announces-its-winners-annual-ces/109362516/"
  },
  {
    name: "Cnn",
    image: logoCnn,
    title: "“Skincare is a intriguing use of the smartphone camera“",
    link:
      "http://money.cnn.com/gallery/technology/2018/01/08/ces-2018-unveiled-gadgets/5.html"
  },
  {
    name: "Business Insider",
    image: logoBusinessInsider,
    title:
      "“Reveal key metrics of skin health that are invisible to the naked eye“",
    link:
      "http://markets.businessinsider.com/news/stocks/Neutrogena-Reveals-the-First-Dermatologist-Grade-At-Home-Skin-Analysis-Tool-at-CES-1012530094"
  },
  {
    name: "Refinery29",
    image: logoRefinery29,
    title: "“Beauty is getting seriously smart”",
    link:
      "http://www.refinery29.com/2018/01/187243/ces-beauty-innovation-loreal-neutrogena"
  },
  {
    name: "WWD",
    image: logoWwd,
    title: "“See what's below the surface”",
    link: "https://fitskin.co/img/assets/wwd_article.png"
  },
  {
    name: "Design Boom",
    image: logoDesignboom,
    title: "“Dermatologist right at your fingertips”",
    link:
      "https://www.designboom.com/technology/neutrogena-skin-scanner-skin360-01-06-2018/#comment-1298952"
  },
  {
    name: "InnovationLVMH",
    image: logoInnovation,
    title: "2017 finalist",
    link:
      "https://www.lvmh.com/news-documents/news/viva-technology-2017-32-finalist-startups-for-lvmh-innovation-award/"
  },
  {
    name: "Johnson&Johnson",
    image: logoJohnson,
    title: "“Dermatologist-grade”",
    link:
      "https://www.multivu.com/players/English/8250251-neutrogena-skin360-skinscanner-fitskin-ces/"
  },
  {
    name: "KellyRyan",
    image: logoKellyRyan,
    title: "“Is this really my skin?”",
    link: "https://kellyandryan.com/uncategorized/february-21-2018/"
  }
];
