import React from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { makeStyles } from "@material-ui/core";

export default function LoadingPage() {
  const styles = useStyles();

  return (
    <div className={styles.mainContainer}>
      <LoadingSpinner />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    position: "absolute",
    top: "50%",
    left: "50%"
  }
}));
