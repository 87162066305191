import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme();

export const FacegeniusTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    //primary: { main: "#0b7ef2" }
    primary: {
      main: "#ef5f03",
      light: "#99A2BF",
      contrastText: "#FCF6E3"
    },
    secondary: {
      main: "#FCF6E3",
      light: "#FCF6E3",
      contrastText: "#ef5f03"
    },
    error: {
      main: "#EA3B6B"
    }
  },
  typography: {
    fontFamily: "Helvetica Neue, Helvetica",
    src: `local('Helvetica'), url(./fonts/Helvetica.otf) format('opentype')`
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        "&:hover": {
          backgroundColor: "rgba(223, 104, 43, 1) !important",
          "@media (hover: none)": {
            backgroundColor: "rgba(223, 103, 43, 1) !important"
          }
        }
      },
      root: {
        height: 48,
        borderRadius: 8,
        textTransform: "none",
        fontSize: "16px",
        "&:hover": {
          backgroundColor: "rgba(252, 246, 227, 1) !important",
          "@media (hover: none)": {
            backgroundColor: "rgba(252, 246, 227, 1) !important"
          }
        }
      }
    }
  }
});
