import Backendless, { BackendlessUser } from "backendless";
import _ from "lodash";
import * as EmailValidator from "email-validator";

type AuthUser = {
  email?: string,
  objectId?: string
};

const STAY_LOGGED_IN = true;

export const login = async (email, password) => {
  try {
    const user = await Backendless.UserService.login(
      email,
      password,
      STAY_LOGGED_IN
    );
    setUserData(user);
    return { user: user };
  } catch (error) {
    setUserData({});
    return { error: error };
  }
};

export const loginGuest = async () => {
  try {
    const user = await Backendless.UserService.loginAsGuest();
    setUserData(user);
    return { user: user };
  } catch (error) {
    setUserData({});
    return { error: error };
  }
};

export const signup = async userData => {
  try {
    const user = await Backendless.UserService.register(userData);
    setUserData({});
    //setUserData(user);
    return { user: user };
  } catch (error) {
    setUserData({});
    return { error: error };
  }
};

export const isLoginValid = async () => {
  try {
    const valid = await Backendless.UserService.isValidLogin();
    return { valid: valid };
  } catch (error) {
    setUserData({});
    return { error: error };
  }
};

export const logout = async () => {
  try {
    await Backendless.UserService.logout();
    setUserData({});
    return { success: true };
  } catch (error) {
    return { error: error };
  }
};

export const resetPassword = async email => {
  try {
    if (!EmailValidator.validate(email)) {
      return { error: "Please enter a valid email" };
    }
    await Backendless.UserService.restorePassword(email);
    console.log("Password reset url sent via email!");
    return { success: true };
  } catch (error) {
    return { error: error };
  }
};

export const resendEmailConfirmation = async email => {
  try {
    if (!EmailValidator.validate(email)) {
      return { error: "Please enter a valid email" };
    }
    await Backendless.UserService.resendEmailConfirmation(email);
    console.log("Confirmation email has been sent.");
    return { success: true };
  } catch (error) {
    return { error: error };
  }
};

// Determines if such user exists in the database
export const findUser = async email => {
  try {
    if (!EmailValidator.validate(email)) {
      return { error: "Please enter a valid email" };
    }
    const query = Backendless.DataQueryBuilder.create();
    query.setWhereClause(`email='${email}'`);
    const userCount = await Backendless.Data.of("Users").getObjectCount(query);
    return { hasUser: userCount > 0, userCount: userCount };
  } catch (error) {
    return { error: error };
  }
};

// Returns user data
export const getUser = async email => {
  try {
    if (!EmailValidator.validate(email)) {
      return { error: "Please enter a valid email" };
    }
    const query = Backendless.DataQueryBuilder.create();
    query.setWhereClause(`email='${email}'`);
    const user = await Backendless.Data.of("Users").find(query);
    return { user: user };
  } catch (error) {
    return { error: error };
  }
};

export const getCurrentUser = async () => {
  try {
    const user = await Backendless.UserService.getCurrentUser();
    setUserData(user);
    return { user: user };
  } catch (error) {
    return { error: error };
  }
};

export const updateUser = async (userData, storeData) => {
  try {
    const user = await Backendless.UserService.update(userData);
    if (!!storeData) {
      setUserData(user);
    }
    return { user: user };
  } catch (error) {
    return { error: error };
  }
};

export const enableUser = async objectId => {
  try {
    const response = await Backendless.CustomServices.invoke(
      "User",
      "enableUser",
      objectId
    );
  } catch (error) {
    return { error: error };
  }
};

export const setUserData = (user: AuthUser) => {
  const _user = _.isEmpty(user) ? {} : user;
  localStorage.setItem("authUser", JSON.stringify(_user));
};

export const clearUserData = () => {
  setUserData({});
};

export const getStoredUser = (): AuthUser => {
  return JSON.parse(localStorage.getItem("authUser"));
};
