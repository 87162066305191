import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import Navigation from "./Navigation.js";
import { FacegeniusTheme } from "styles/FacegeniusTheme.js";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={FacegeniusTheme}>
        <Navigation />
      </ThemeProvider>
    </div>
  );
}

export default App;
