import React, { useState, useEffect, Suspense } from "react";
import { navigate } from "@reach/router";
import { isLoginValid } from "services/auth";
import { Pages } from "Navigation";
import LoadingSpinner from "components/LoadingSpinner";
import ErrorBoundary from "components/ErrorBoundary";
import LoadingPage from "pages/LoadingPage";
import { Helmet } from "react-helmet";
import _ from "lodash";

type MetaTag = {
  name?: string,
  property?: string,
  content: string
};
type PageProps = {
  component: any,
  title?: string,
  description?: string,
  metaTags?: MetaTag[]
};

export const BasicPage = (props: PageProps) => {
  // TODO: log errors for missing properties
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingPage />}>
        <Helmet
          titleTemplate="%s - FaceGenius®"
          defaultTitle={"FaceGenius® - The future of skincare is here"}
        >
          {!!props.title && <title>{props.title}</title>}
          {!!props.description && (
            <meta name="description" content={props.description} />
          )}
          {!!props.metaTags &&
            _.map(props.metaTags, tag => (
              <meta
                title={tag.title}
                property={tag.property}
                content={tag.content}
              />
            ))}
        </Helmet>
        {props.component && <props.component {...props} />}
      </Suspense>
    </ErrorBoundary>
  );
};

export const PrivatePage = (props: PageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      const { valid, error } = await isLoginValid();
      setIsLoading(false);

      if (error) {
        setLoggedIn(false);
        navigate(Pages.Login, { state: { pageRedirect: props.path } });
      } else {
        setLoggedIn(valid);
        if (!valid) {
          navigate(Pages.Login, { state: { pageRedirect: props.path } });
        }
      }
    };

    checkLogin();
  }, []);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && isLoggedIn && <BasicPage {...props} />}
    </>
  );
};
