import iconPlan30 from "img/iconPlanNight.svg";
import iconPlan50 from "img/iconPlanMorningNight.svg";

export { ProductsList } from "data/brands.js";
export { AwardsList } from "data/awards.js";

export const TERMS_URL = process.env.PUBLIC_URL + "/FaceGeniusTermsOfUse.pdf";

export type PlanInfo = {
  title: string,
  description: string,
  price: string,
  priceValue: number,
  icon: any, // Icon type
  stripePlanId: string
};

export type FullDetails = {
  firstName: string,
  lastName: string,
  addressLine1: string,
  addressLine2?: string,
  city: string,
  state: string,
  country: string,
  postal_code: string,
  phone?: string
};

export const SeasonDates = {
  Winter: { month: 0, day: 1 }, // January 1 for moment-js
  Spring: { month: 3, day: 1 }, // April 1 for moment-js
  Summer: { month: 6, day: 1 }, // July 1 for moment-js
  Fall: { month: 9, day: 1 } // October 1 for moment-js
};

export const planList: PlanInfo = [
  {
    title: "Morning & Night Plan",
    // Description is deprecated now
    description: [
      "1 cleanser, 1 serum and 1 moisturizer",
      "Full-size products (value $600+/yr)",
      "SkinScanner & iOS mobile app"
    ],
    price: "$150",
    priceNum: 150,
    priceMonthly: "$50/month",
    priceYearly: "$600",
    icon: iconPlan50,
    stripePlanId: process.env.REACT_APP_STRIPE_PLAN_50_ID,
    code: "dollar50",
    tier: "Morning_Night"
  },
  {
    title: "Night Plan",
    description: [
      "1 cleanser, 1 serum and 1 moisturizer",
      "Full-size products (value $360+/yr)",
      "SkinScanner & iOS mobile app"
    ],
    price: "$90",
    priceNum: 90,
    priceMonthly: "$30/month",
    priceYearly: "$360",
    icon: iconPlan30,
    stripePlanId: process.env.REACT_APP_STRIPE_PLAN_30_ID,
    code: "dollar30",
    tier: "Night"
  }
];
